import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout";
import Link from "../components/Atoms/Link";
import { withTrans } from "../i18n/withTrans";
import { adapter as seoAdapter } from "../components/seo";
import { mediaquery, scaleSpacing } from "../../styles/theme";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import ArticlePage from "../templates/article";
import {
  OswaldH1,
  OswaldH2,
  OswaldH6,
  NotoLink,
} from "../components/Atoms/Typography";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

const Title = styled.h3`
  ${OswaldH2};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    ${OswaldH1};
    margin-top: ${scaleSpacing(4)};
  `)};
`;

const Text = styled.span`
  ${OswaldH6};
  color: inherit;
  margin: ${scaleSpacing(6)} ${scaleSpacing(3)};
  text-align: center;

  ${mediaquery.md(css`
    margin: 0 0 ${scaleSpacing(6)} 0;
  `)};
`;
const BackLink = styled(Link)`
  ${NotoLink};
  color: inherit;
`;

const ErrorPage = ({ location }) => {
  const { t } = useTranslation();
  return (
    <Layout
      seoData={seoAdapter({
        status: "404",
      })}
      location={location}
    >
      <Wrapper>
        <Title>404</Title>
        <Text>{t("page_not_found_subtitle_label")}</Text>
        <BackLink linkTheme="dark" link={{ url: "/" }}>
          {t("page_not_found_return_label")}
        </BackLink>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query homeTroisCouleursQuerytest {
    ...i18n
  }
`;

ErrorPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

ErrorPage.defaultProps = {
  data: {},
};
// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(withTrans(ErrorPage), {
  templateMap: {
    article: ArticlePage,
  },
});
